import {LinkProps} from "next/link";
import React from "react";

import {noOp} from "../../../utils/noOp";
import {shouldAllowReferrer} from "../../../utils/shouldAllowReferrer";
import LinkOrButton from "../LinkOrButton";
import {getButtonStyle} from "./styles";
import {ButtonShape, ButtonSize, ButtonVariant} from ".";

const getRelAttribute = (href: LinkProps["href"] | null | undefined, newTab?: boolean) =>
  newTab && href ? (shouldAllowReferrer(href) ? "noopener" : "noopener noreferrer") : undefined;

const Button = ({
  variant = ButtonVariant.FILL,
  size = ButtonSize.MD,
  shape = ButtonShape.ROUND,
  children,
  onClick,
  href,
  newTab,
  disabled = false,
  className = "",
  ariaLabel,
  shallow,
  as,
  ...props
}: {
  variant?: ButtonVariant;
  size?: ButtonSize;
  shape?: ButtonShape;
  children: React.ReactNode;
  onClick?: React.MouseEventHandler;
  href?: LinkProps["href"] | null;
  shallow?: LinkProps["shallow"];
  as?: LinkProps["as"];
  newTab?: boolean;
  disabled?: boolean;
  className?: string;
  ariaLabel?: string;
  "data-cy"?: string;
}): React.ReactElement => (
  <LinkOrButton
    href={disabled ? undefined : href}
    target={newTab ? "_blank" : undefined}
    rel={getRelAttribute(href, newTab)}
    onClick={disabled ? noOp : onClick}
    className={getButtonStyle(variant, size, shape, className)}
    disabled={disabled}
    aria-label={ariaLabel}
    shallow={shallow}
    as={as}
    data-cy={props["data-cy"]}
  >
    {children}
  </LinkOrButton>
);

export default React.memo(Button);
